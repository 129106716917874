import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './alternatives.loaders';
import { alternativesKeys } from './alternatives.queries';

export const useAssignEmployees = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.assignEmployees, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useAssignEmployeesGroups = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.assignEmployeesGroups, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useCreateAlternative = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createAlternative, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useRemoveAlternative = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeAlternative, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useLockSelection = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.lockSelection, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useSelectBenefits = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.selectBenefits, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useChangeDeadline = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.changeDeadline, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useBulkSelectBenefits = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkSelectBenefits, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useBulkLockSelection = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkLockSelection, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useActivateAlternative = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.activate, {
    onSuccess: () => {
      return queryClient.invalidateQueries(alternativesKeys.all);
    },
  });
};

export const useExportToXlsEmployees = () => {
  return useMutation(loaders.exportToXlsEmployees, {
    onSuccess: filesConverters.export,
  });
};

export const useExportToXls = () => {
  return useMutation(loaders.exportToXls, {
    onSuccess: filesConverters.export,
  });
};
