import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import {
  IAlternative,
  IAlternativeEmployee,
  IAlternativeEmployeesGroup,
  IBaseAlternative,
} from 'types/income';
import { exportXlsxConfig } from 'utils/general';
import {
  AlternativeEmployeesFilters,
  AlternativesFilters,
  ExportAlternativesFilters,
} from './alternatives.types';

interface GetAlternatives {
  filters: Partial<AlternativesFilters>;
}

interface GetAlternative {
  alternativeId: string;
}

interface GetEmployees {
  alternativeId: string;
  filters: Partial<AlternativeEmployeesFilters>;
}

interface GetEmployeesGroups {
  alternativeId: string;
}

interface AssignEmployees {
  alternativeId: string;
  assign: string[];
  unassign: string[];
}

interface AssignEmployeesGroups {
  alternativeId: string;
  assign: string[];
  unassign: string[];
}

interface CreateAlternative {
  name: string;
}

interface RemoveAlternative {
  alternativeId: string;
}

interface LockSelection {
  alternativeId: string;
  employeeId: string;
}

interface SelectBenefits {
  alternativeId: string;
  employeeId: string;
  benefitIds: string[];
}

interface ChangeDeadline {
  alternativeId: string;
  data: {
    employees: string[];
    date: string;
  };
}

interface BulkSelectBenefits {
  alternativeId: string;
  data: {
    benefits: string[];
    employees: string[];
  };
}

interface BulkLockSelection {
  alternativeId: string;
  data: {
    employees: string[];
  };
}

interface Activate {
  alternativeId: string;
  isActive: boolean;
}

interface ExportXls {
  filters: Partial<ExportAlternativesFilters>;
}

interface ExportXlsEmployees {
  alternativeId: string;
  filters: Partial<AlternativeEmployeesFilters>;
}

export const loaders = {
  getAlternatives: ({ filters }: GetAlternatives) => {
    return api<TableDataResponse<IBaseAlternative>>({
      url: Endpoints.clientAdmin.alternatives.root,
      params: {
        filter: {
          companyIds: filters.company,
          departmentIds: filters.department,
          customCategoryId: filters.customCategory || undefined,
          customCategoryValueIds: filters.customCategoryValue,
        },
      },
    });
  },
  getAlternative: ({ alternativeId }: GetAlternative) => {
    return api<IAlternative>({
      url: Endpoints.clientAdmin.alternatives.byId.root.replace(
        ':alternativeId',
        alternativeId,
      ),
    });
  },
  getEmployees: ({ alternativeId, filters }: GetEmployees) => {
    return api<TableDataResponse<IAlternativeEmployee>>({
      url: Endpoints.clientAdmin.alternatives.byId.employees.root.replace(
        ':alternativeId',
        alternativeId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        search: filters.search || undefined,
        filter: {
          status: filters.status,
          assignmentStatus: filters.assignmentStatus,
          benefitId: filters.benefits,
          benefitCategories: filters.benefitCategories,
          companyIds: filters.companies,
          departmentIds: filters.departments,
          customCategoryId: filters.customCategory || undefined,
          customCategoryValueIds: filters.customCategoryValues,
          dateFrom: filters.expiresAt?.from,
          dateTo: filters.expiresAt?.to,
        },
      },
    });
  },
  getEmployeesGroups: ({ alternativeId }: GetEmployeesGroups) => {
    return api<TableDataResponse<IAlternativeEmployeesGroup>>({
      url: Endpoints.clientAdmin.alternatives.byId.employeeGroups.root.replace(
        ':alternativeId',
        alternativeId,
      ),
    });
  },
  assignEmployees: ({ alternativeId, assign, unassign }: AssignEmployees) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.byId.employees.assignments.replace(
        ':alternativeId',
        alternativeId,
      ),
      data: {
        assign,
        unassign,
      },
      method: 'patch',
    });
  },
  assignEmployeesGroups: ({
    alternativeId,
    assign,
    unassign,
  }: AssignEmployeesGroups) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.byId.employeeGroups.assignments.replace(
        ':alternativeId',
        alternativeId,
      ),
      data: {
        assign,
        unassign,
      },
      method: 'patch',
    });
  },
  createAlternative: ({ name }: CreateAlternative) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.alternatives.root_v1,
      data: {
        name,
      },
      method: 'post',
    });
  },
  removeAlternative: ({ alternativeId }: RemoveAlternative) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.byId.root.replace(
        ':alternativeId',
        alternativeId,
      ),
      method: 'delete',
    });
  },
  lockSelection: ({ alternativeId, employeeId }: LockSelection) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.byId.employees.byId.lockSelection
        .replace(':alternativeId', alternativeId)
        .replace(':employeeId', employeeId),
      method: 'patch',
    });
  },
  selectBenefits: ({
    alternativeId,
    employeeId,
    benefitIds,
  }: SelectBenefits) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.byId.employees.byId.selectBenefits
        .replace(':alternativeId', alternativeId)
        .replace(':employeeId', employeeId),
      data: { benefitIds },
      method: 'patch',
    });
  },
  changeDeadline: ({ alternativeId, data }: ChangeDeadline) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.byId.deadline.replace(
        ':alternativeId',
        alternativeId,
      ),
      data: {
        employeeIds: data.employees,
        date: data.date,
      },
      method: 'patch',
    });
  },
  bulkSelectBenefits: ({ alternativeId, data }: BulkSelectBenefits) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.byId.selectBenefits.replace(
        ':alternativeId',
        alternativeId,
      ),
      data: {
        employeeIds: data.employees,
        benefitIds: data.benefits,
      },
      method: 'patch',
    });
  },
  bulkLockSelection: ({ alternativeId, data }: BulkLockSelection) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.byId.lockSelection.replace(
        ':alternativeId',
        alternativeId,
      ),
      data: {
        employeeIds: data.employees,
      },
      method: 'patch',
    });
  },
  activate: ({ alternativeId, isActive }: Activate) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.alternatives.byId.status.replace(
        ':alternativeId',
        alternativeId,
      ),
      data: { isActive },
      method: 'patch',
    });
  },
  exportToXlsEmployees: ({ alternativeId, filters }: ExportXlsEmployees) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.export.xls,
      params: {
        search: filters.search || undefined,
        filter: {
          alternativeIds: [alternativeId],
          // TODO: add filters when BE is fixed
          // status: filters.status,
          // assignmentStatus: filters.assignmentStatus,
          // benefitId: filters.benefits,
          // benefitCategories: filters.benefitCategories,
          // companyIds: filters.companies,
          // departmentIds: filters.departments,
          // customCategoryId: filters.customCategory || undefined,
          // customCategoryValueIds: filters.customCategoryValues,
          // dateFrom: filters.expiresAt?.from,
          // dateTo: filters.expiresAt?.to,
        },
      },
      ...exportXlsxConfig,
    });
  },
  exportToXls: ({ filters }: ExportXls) => {
    return api({
      url: Endpoints.clientAdmin.alternatives.export.xls,
      params: {
        filter: {
          alternativeIds: filters.alternatives,
        },
      },
      ...exportXlsxConfig,
    });
  },
};
